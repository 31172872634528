import React from 'react';

function ErrorPage() {
  return (
    <div className="error-page">
        <h2>Error 404</h2>
        <h2>Sorry, page not found 😞 </h2>
    </div>
  )
}

export default ErrorPage;